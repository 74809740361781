import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { TuiAvatarModule } from '@taiga-ui/kit';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiHostedDropdownModule,
  TuiSvgModule,
} from '@taiga-ui/core';
import { AuthState } from '@store/auth/auth.state';
import { Store } from '@ngxs/store';
import { tuiIconChevronDown } from '@taiga-ui/icons';
import { DROPDOWN_MENU_ITEMS, MenuItem } from '../../menu';
import { OrganizationRouteNames } from '../../../../route-names';
import { Role, SUPER_ADMINS } from '../../../../types/auth';

@Component({
  selector: 'app-dropdown-menu',
  standalone: true,
  imports: [
    RouterLink,
    TuiAvatarModule,
    TuiDataListModule,
    TuiHostedDropdownModule,
    TuiSvgModule,
    TuiButtonModule,
  ],
  templateUrl: './dropdown-menu.component.html',
  styleUrl: './dropdown-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownMenuComponent {
  isAuthenticated = this.store.selectSignal(AuthState.isAuthenticated);
  user = this.store.selectSignal(AuthState.getUser);
  userRole = this.store.selectSignal(AuthState.getUserRole);
  availableMenuItems = computed(() => {
    const superAdmin = SUPER_ADMINS.includes(this.user()?.email ?? '');
    return DROPDOWN_MENU_ITEMS.filter(menuItem => {
      if (menuItem?.authenticated) {
        return this.isAuthenticated();
      }
      return true;
    }).filter(menuItem => {
      const role = this.userRole();
      return (
        superAdmin ||
        (menuItem.roles?.length ? menuItem.roles?.includes(role as Role) : true)
      );
    });
  });

  protected readonly tuiIconChevronDown = tuiIconChevronDown;
  constructor(
    private readonly store: Store,
    private readonly router: Router,
  ) {}

  navigate(route: MenuItem) {
    if (route.path === OrganizationRouteNames.DETAILS) {
      const divisionId = this.store.selectSnapshot(
        AuthState.getUserActiveDivision,
      );
      if (divisionId) {
        const path = route.path.replace(':id', divisionId.toString());
        return this.router.navigate([path]);
      }
      return;
    }
    return this.router.navigate([route.path]);
  }
}
